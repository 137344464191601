<template>
    <comp-table ref="comp_table" title="系统消息" entity="BotRecord" :columns="columns" tableApi="WEB://BotRecord/FindAllToPage"></comp-table>
</template>

<script>
import CompTable from "@/components/comp-table.vue"
// import Request from "@/module/request/request"

export default {
    components: {
        CompTable,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "消息",
                    key: "message",
                },
                {
                    title: "创建时间",
                    key: "createdDate",
                },
            ],
        }
    },
}
</script>

<style></style>
